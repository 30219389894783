import './DownLoad.css';

function DownLoad() {
  return (
    <div className="App">
      <div className='App-header'>
        <div className='App-header-text-div'>
          <img src='https://wimgp.com/WImToolslogo.png' alt='Wim工具' className='App-hearder-logo'></img>
          <span className='App-hearder-text'>WIM 工具</span>
        </div>
      </div>
      <div className="App-body">
        <div className='App-div'>
          <div className='App-text-div'>
            <a
              className="App-link"
              href="https://wimgp.com/WimTool.zip"
              download='WimTool'
            >
              下 载
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownLoad;
